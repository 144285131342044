.caption_wrapper {
  max-height: 20px;
  margin-bottom: 16px;
  overflow-y: hidden;
}

.caption_container {
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: var(--textBodySw);
  gap: 4px;
}

.caption_container:not(:has(.signature_link)) {
  justify-content: center;
}

.caption_item {
  display: flex;
  gap: 4px;
  align-items: center;
  text-wrap: nowrap;
}

.signature_link {
  display: flex;
  gap: 4px;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.signature_text {
  text-align: center;
  text-overflow: ellipsis;
  line-clamp: 1;
  overflow: hidden;
}

.signature_logo {
  max-width: 80px;
  max-height: 20px;
  width: auto;
  height: auto;
}

@media (max-width: 399px) {
  .caption_wrapper {
    margin-bottom: 10px;
  }

  .span {
    display: none;
  }
}
