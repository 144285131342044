.option {
  display: flex;
  padding: 20px 32px;
  cursor: pointer;

  text-decoration: none;
  color: var(--textBodyMain);
}

.option .arrow_icon {
  transition: all 500ms;
  transform: translate(0px);
}

.option:hover {
  background: var(--dieDefault);
}

.option:hover .arrow_icon {
  transform: translate(10px);
}

.option_label {
  font-size: 18px;
  line-height: 24px;
  color: var(--textBodyMain);
  flex: 1;
  margin-left: 12px;
}
