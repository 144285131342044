.wrapper {
  padding: 0 var(--web-sides-padding);
  display: flex;
  gap: 10px;
  flex-direction: column;
  z-index: 10;
}

.wrapper_with_caption {
  padding-bottom: 0;
}

@media (max-width: 399px) {
  .wrapper {
    padding: 0 var(--mobile-sides-padding);
  }
}
