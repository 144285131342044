.tooltip__wrapper {
  position: relative;

  display: flex;
  place-items: center;

  cursor: pointer;
}

.tooltip__component {
  width: auto;
  padding: 8px 12px;

  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;

  border-radius: 8px;
  color: var(--background);
  background-color: var(--textBodyMain);
  border: 1px solid var(--textBodyAdd);

  position: absolute;
  z-index: 999;
  left: 50%;

  opacity: 0;
  visibility: hidden;
}

.tooltip__component_type_top {
  top: -15px;
  transform: translate(-50%, -100%);
}

.tooltip__component_type_bottom {
  top: 25px;
  transform: translate(-50%, 0%);
}

.tooltip__component_type_visible {
  opacity: 1;
  visibility: visible;
}

.tooltip__corner {
  width: 24px;
  height: 12px;
  margin-left: -12px;

  position: absolute;
  left: 50%;

  overflow: hidden;
  border-radius: 3px;
}
.tooltip__corner::after {
  content: '';
  width: 10px;
  height: 10px;

  position: absolute;
  left: 50%;

  background-color: var(--textBodyMain);
  border: 1px solid var(--textBodyAdd);
  border-radius: 3px;
}

.tooltip__corner_type_top {
  top: 100%;
}
.tooltip__corner_type_top::after {
  transform: translate(-50%, -60%) rotate(45deg);
}

.tooltip__corner_type_bottom {
  top: -12px;
}
.tooltip__corner_type_bottom::after {
  transform: translate(-50%, 60%) rotate(45deg);
}
