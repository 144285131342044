.wrapper {
  min-height: 602px;
  display: flex;
  flex-direction: column;
}

.wrapper_type_blocked {
  pointer-events: none;
  opacity: 0.8;
}

.body_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 var(--web-sides-padding);
}

.body_wrapper_without_padding {
  padding: 0;
}

.body_wrapper_position_relative {
  position: relative;
}

.line {
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--dieDefault);
}

.percent_line {
  background: var(--main);
  height: 100%;
  width: 100%;
}

.percent_line.percent_line_default {
  background: linear-gradient(268deg, #ff6f00 7.19%, #ffe27a 100%);
}

@media (max-width: 399px) {
  .body_wrapper {
    padding: 0 var(--mobile-sides-padding);
  }
}
