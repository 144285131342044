.wrapper {
}

.input_wrapper {
  width: 100%;
  border-bottom: 1px solid;
  border-color: var(--bordersDefault);
  padding: 12px 0;
  display: flex;
}

.input_wrapper_error {
  border-color: var(--warning);
}

.label {
  color: var(--textBodyAdd);
  font-size: 14px;
  line-height: 20px;
}

.label_error {
  color: var(--warning);
}

@media (max-width: 399px) {
  .label {
    font-size: 18px;
    line-height: 24px;
  }
}
