.container {
  min-height: 600px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 var(--web-sides-padding);
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.img {
  width: 220px;
  height: 220px;
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: var(--textBodyMain);
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--textBodyAdd);
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  gap: 16px;
}

.caption {
  color: var(--textBodySw);
  font-family: Inter, Arial sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
}
