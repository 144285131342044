.chip {
  align-self: stretch;
  background: var(--dieDefault);
  color: var(--textBodyAdd);
  user-select: none;
  min-height: 100%;
  border-radius: 40px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.label {
  flex: 1;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--textBodyMain);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pay_info_chip_span {
  overflow: hidden;
  white-space: nowrap;
}

.received_info_chip_span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90px;
}
