.trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.trigger > svg {
  width: 28px;
  height: 28px;

  background-color: var(--dieDefault);
  border: 1px solid var(--borderMain);
  border-radius: 50%;
}

.locale_container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  gap: 4px;
}

.locale_origin_name {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--textBodyMain);
}

.locale_translated_name {
  font-size: 14px;
  line-height: 18px;
  color: var(--textBodyAdd);
}
